// Exists as part of layout.js

import { Link } from "gatsby"
import React from "react"
import gbhLogo from "../images/gbh_logo_purple_rgb.png"

let year = new Date().getFullYear()

const Footer = () => (
    <footer>
        <div className="wrapper">
            <div className="main">
                <ul>
                    <li><Link to="/" activeClassName="active">Home</Link></li>
                    <li><a href="https://www.youtube.com/channel/UCL8AY9rBzjgtF0LmDWu1OpA/"  rel="noopener noreferrer" target="_blank">Watch</a></li>
                    <li><Link to="/about/" activeClassName="active">About the Series</Link></li>
                    <li><Link to="/info/" activeClassName="active">Social 101</Link></li>
                </ul>
            </div>

            <div className="secondary">
                <div className="info">
                    <p>&copy; {year} WGBH Educational Foundation.</p>
                    <p className="produced">Produced by <a href="https://www.wgbh.org" rel="noopener noreferrer" target="_blank"><img src={gbhLogo} alt="WGBH Educational Foundation Logo. "/></a></p>
                </div>
                <ul className="legal">
                    <li><a rel="noopener noreferrer" href="https://www.wgbh.org/help/2018/04/30/terms-of-use" target="_blank">Terms of Use</a></li>
                    <li><a rel="noopener noreferrer" href="https://www.wgbh.org/help/2018/04/30/privacy-policy" target="_blank">Privacy</a></li>
                </ul>
            </div>
        </div>
    </footer>
)

 export default Footer
