// Part of layout.js

import { Link } from "gatsby"
import React from "react"
import Follow from "./follow-social"

export default (props) => (
    <header className="navigation">
        <Follow />
        <div className="wrapper">
            <Link className="main-menu-link" to="/"><img className="header-logo" src="https://s3.amazonaws.com/wgbh-digital/keepitsocial/images/logo-kis-dark.png" alt="Keep it Social. "/></Link>
            <a className="main-menu-link" href="https://www.youtube.com/channel/UCL8AY9rBzjgtF0LmDWu1OpA/"  rel="noopener noreferrer" target="_blank">Watch</a>
            <Link className="main-menu-link" to="/about/" activeClassName="active">About the Series</Link>
            <Link className="main-menu-link" to="/info/" activeClassName="active">Social 101</Link>
        </div>
    </header>
)
