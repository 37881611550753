// Exists as part of header.js

import React from "react"

const Follow = () => (
    <ul className="follow">
        <li>Follow Us</li>
        <li><a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCL8AY9rBzjgtF0LmDWu1OpA/" target="_blank" className="youtube" title="Follow us on YouTube. ">Youtube</a></li>
         <li><a rel="noopener noreferrer" href="https://www.twitter.com/kis_wgbh" target="_blank" className="twitter" title="Follow us on Twitter. ">Twitter</a></li>
        <li><a rel="noopener noreferrer" href="https://www.instagram.com/KeepItSocialWGBH" target="_blank" className="instagram" title="Follow us on Instagram. ">Instagram</a></li>
        <li><a rel="noopener noreferrer" href="https://www.facebook.com/KeepItSocialWGBH/" target="_blank" className="facebook" title="Follow us on Facebook. ">Facebook</a></li>
    </ul>
)

export default Follow
